import React, { useState } from "react"
import { Link } from "gatsby"
// Components
import Seo from "../../../components/seo"
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import ContentNavigation from "../../../components/scrollspy"

// SEO Image
import ogImage from "../../../images/seo/ratings/ratings.png"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import RatingHeroComponent from "../../../library/components/rating/react/_preview/RatingHeroComponent"

import RatingAllBasicPreview from "../../../library/components/rating/react/_preview/basic"
import RatingAllListPreview from "../../../library/components/rating/react/_preview/list"
import RatingAllDetailedPreview from "../../../library/components/rating/react/_preview/detailed"
import RatingAllBrandPreview from "../../../library/components/rating/react/_preview/brand"
import RatingAllCardPreview from "../../../library/components/rating/react/_preview/card"
import RatingAllCardListPreview from "../../../library/components/rating/react/_preview/card-list"
import RatingAllCardDetailedPreview from "../../../library/components/rating/react/_preview/card-detailed"
import RatingAllCardBrandPreview from "../../../library/components/rating/react/_preview/card-brand"

// Basic
const RatingBasicBasicJsx = require("!!raw-loader!../../../library/components/rating/react/basic/basic.jsx")
const RatingBasicBasicHTML = require("!!raw-loader!../../../library/components/rating/html/basic/basic.html")

const RatingBasicEmphasizedJsx = require("!!raw-loader!../../../library/components/rating/react/basic/emphasized.jsx")
const RatingBasicEmphasizedHTML = require("!!raw-loader!../../../library/components/rating/html/basic/emphasized.html")

// List
const RatingListBasicJsx = require("!!raw-loader!../../../library/components/rating/react/list/basic.jsx")
const RatingListBasicHTML = require("!!raw-loader!../../../library/components/rating/html/list/basic.html")

const RatingListDetailedJsx = require("!!raw-loader!../../../library/components/rating/react/list/detailed.jsx")
const RatingListDetailedHTML = require("!!raw-loader!../../../library/components/rating/html/list/detailed.html")

// Detailed
const RatingDetailedBasicJsx = require("!!raw-loader!../../../library/components/rating/react/detailed/basic.jsx")
const RatingDetailedBasicHTML = require("!!raw-loader!../../../library/components/rating/html/detailed/basic.html")

const RatingDetailedEmphasizedJsx = require("!!raw-loader!../../../library/components/rating/react/detailed/emphasized.jsx")
const RatingDetailedEmphasizedHTML = require("!!raw-loader!../../../library/components/rating/html/detailed/emphasized.html")

// Brand
const RatingBrandBasicJsx = require("!!raw-loader!../../../library/components/rating/react/brand/basic.jsx")
const RatingBrandBasicHTML = require("!!raw-loader!../../../library/components/rating/html/brand/basic.html")

const RatingBrandEmphasizedJsx = require("!!raw-loader!../../../library/components/rating/react/brand/emphasized.jsx")
const RatingBrandEmphasizedHTML = require("!!raw-loader!../../../library/components/rating/html/brand/emphasized.html")

// Card
const RatingCardBasicJsx = require("!!raw-loader!../../../library/components/rating/react/card/basic.jsx")
const RatingCardBasicHTML = require("!!raw-loader!../../../library/components/rating/html/card/basic.html")

const RatingCardBasicEmphasizedJsx = require("!!raw-loader!../../../library/components/rating/react/card/basic-emphasized.jsx")
const RatingCardBasicEmphasizedHTML = require("!!raw-loader!../../../library/components/rating/html/card/basic-emphasized.html")

// Card List
const RatingCardBrandListBasicJsx = require("!!raw-loader!../../../library/components/rating/react/card/brand-list-basic.jsx")
const RatingCardBrandListBasicHTML = require("!!raw-loader!../../../library/components/rating/html/card/brand-list-basic.html")

const RatingCardBrandListDetailedJsx = require("!!raw-loader!../../../library/components/rating/react/card/brand-list-detailed.jsx")
const RatingCardBrandListDetailedHTML = require("!!raw-loader!../../../library/components/rating/html/card/brand-list-detailed.html")

// Card Detailed
const RatingCardDetailedJsx = require("!!raw-loader!../../../library/components/rating/react/card/detailed.jsx")
const RatingCardDetailedHTML = require("!!raw-loader!../../../library/components/rating/html/card/detailed.html")

const RatingCardDetailedEmphasizedJsx = require("!!raw-loader!../../../library/components/rating/react/card/detailed-emphasized.jsx")
const RatingCardDetailedEmphasizedHTML = require("!!raw-loader!../../../library/components/rating/html/card/detailed-emphasized.html")

// Card Brand
const RatingCardBrandJsx = require("!!raw-loader!../../../library/components/rating/react/card/brand.jsx")
const RatingCardBrandHTML = require("!!raw-loader!../../../library/components/rating/html/card/brand.html")

const RatingCardBrandEmphasizedJsx = require("!!raw-loader!../../../library/components/rating/react/card/brand-emphasized.jsx")
const RatingCardBrandEmphasizedHTML = require("!!raw-loader!../../../library/components/rating/html/card/brand-emphasized.html")

export default function RatingsPage() {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "RatingAllBasic",
      title: "Basic",
      active_tab: 1,
    },
    {
      component_name: "RatingAllList",
      title: "List",
      active_tab: 1,
    },
    {
      component_name: "RatingAllDetailed",
      title: "Detailed",
      active_tab: 1,
    },
    {
      component_name: "RatingAllBrand",
      title: "Brand",
      active_tab: 1,
    },
    {
      component_name: "RatingAllCard",
      title: "Card",
      active_tab: 1,
    },
    {
      component_name: "RatingAllCardList",
      title: "Card List",
      active_tab: 1,
    },
    {
      component_name: "RatingAllCardDetailed",
      title: "Card Detailed",
      active_tab: 1,
    },
    {
      component_name: "RatingAllCardBrand",
      title: "Card Brand",
      active_tab: 1,
    },
  ])

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Ratings - WindUI Component Library"
        ogtitle="Tailwind CSS Ratings - WindUI Component Library"
        description="Rating components allow users to view and/or set a star rating for a product or a service. Built with Tailwind CSS by WindUI."
        ogdescription="Rating components allow users to view and/or set a star rating for a product or a service. Built with Tailwind CSS by WindUI."
        url="components/ratings/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Ratings, Ratings, Rating Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Ratings</h1>
          <p>
            The ratings component allows users to view and/or set a star rating
            for a product or a service.
          </p>

          {/* Hero Preview Section */}
          <section
            className="not-prose max-w-full"
            aria-multiselectable="false"
          >
            <div className="flex w-full items-center justify-center rounded-xl bg-wuiSlate-50 p-8 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200 sm:p-16">
              <RatingHeroComponent />
            </div>
          </section>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Basic */}
          <h3 id="RatingAllBasic">Basic</h3>

          <PreviewBlock
            id="RatingAllBasic"
            HtmlComponent={RatingBasicBasicHTML.default}
            JsxComponent={RatingBasicBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full flex-col items-center justify-center gap-28 rounded-xl bg-white p-8 sm:p-16 [&>*]:w-80 [&>*]:max-w-full ">
              <RatingAllBasicPreview
                RatingBasicBasic={
                  activeTabs[0].active_tab === 1
                    ? RatingBasicBasicHTML.default
                    : RatingBasicBasicJsx.default
                }
                RatingBasicEmphasized={
                  activeTabs[0].active_tab === 1
                    ? RatingBasicEmphasizedHTML.default
                    : RatingBasicEmphasizedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* List */}
          <h3 id="RatingAllList">List</h3>

          <PreviewBlock
            id="RatingAllList"
            HtmlComponent={RatingListBasicHTML.default}
            JsxComponent={RatingListBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full flex-col items-center justify-center gap-28 rounded-xl bg-white p-8 sm:p-16 [&>*]:w-80 [&>*]:max-w-full ">
              <RatingAllListPreview
                RatingListBasic={
                  activeTabs[1].active_tab === 1
                    ? RatingListBasicHTML.default
                    : RatingListBasicJsx.default
                }
                RatingListDetailed={
                  activeTabs[1].active_tab === 1
                    ? RatingListDetailedHTML.default
                    : RatingListDetailedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Detailed */}
          <h3 id="RatingAllDetailed">Detailed</h3>

          <PreviewBlock
            id="RatingAllDetailed"
            HtmlComponent={RatingDetailedBasicHTML.default}
            JsxComponent={RatingDetailedBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full flex-col items-center justify-center gap-28 rounded-xl bg-white p-8 sm:p-16 [&>*]:w-80 [&>*]:max-w-full">
              <RatingAllDetailedPreview
                RatingDetailedBasic={
                  activeTabs[2].active_tab === 1
                    ? RatingDetailedBasicHTML.default
                    : RatingDetailedBasicJsx.default
                }
                RatingDetailedEmphasized={
                  activeTabs[2].active_tab === 1
                    ? RatingDetailedEmphasizedHTML.default
                    : RatingDetailedEmphasizedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Brand */}
          <h3 id="RatingAllBrand">Brand</h3>

          <PreviewBlock
            id="RatingAllBrand"
            HtmlComponent={RatingBrandBasicHTML.default}
            JsxComponent={RatingBrandBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full flex-col items-center justify-center gap-28 rounded-xl bg-white p-8 sm:p-16">
              <RatingAllBrandPreview
                RatingBrandBasic={
                  activeTabs[3].active_tab === 1
                    ? RatingBrandBasicHTML.default
                    : RatingBrandBasicJsx.default
                }
                RatingBrandEmphasized={
                  activeTabs[3].active_tab === 1
                    ? RatingBrandEmphasizedHTML.default
                    : RatingBrandEmphasizedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Card */}
          <h3 id="RatingAllCard">Card</h3>

          <PreviewBlock
            id="RatingAllCard"
            HtmlComponent={RatingCardBasicHTML.default}
            JsxComponent={RatingCardBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full flex-col items-center justify-center gap-28 rounded-xl bg-wuiSlate-50 p-8 sm:p-16 [&>*]:w-80 [&>*]:max-w-full ">
              <RatingAllCardPreview
                RatingCardBasic={
                  activeTabs[4].active_tab === 1
                    ? RatingCardBasicHTML.default
                    : RatingCardBasicJsx.default
                }
                RatingCardBasicEmphasized={
                  activeTabs[4].active_tab === 1
                    ? RatingCardBasicEmphasizedHTML.default
                    : RatingCardBasicEmphasizedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Basic */}
          <h3 id="RatingAllCardList">Card List</h3>

          <PreviewBlock
            id="RatingAllCardList"
            HtmlComponent={RatingCardBrandListBasicHTML.default}
            JsxComponent={RatingCardBrandListBasicJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full flex-col items-center justify-center gap-28 rounded-xl bg-wuiSlate-50 p-8 sm:p-16 [&>*]:w-80 [&>*]:max-w-full">
              <RatingAllCardListPreview
                RatingCardBrandListBasic={
                  activeTabs[5].active_tab === 1
                    ? RatingCardBrandListBasicHTML.default
                    : RatingCardBrandListBasicJsx.default
                }
                RatingCardBrandListDetailed={
                  activeTabs[5].active_tab === 1
                    ? RatingCardBrandListDetailedHTML.default
                    : RatingCardBrandListDetailedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Basic */}
          <h3 id="RatingAllCardDetailed">Card Detailed</h3>

          <PreviewBlock
            id="RatingAllCardDetailed"
            HtmlComponent={RatingCardDetailedHTML.default}
            JsxComponent={RatingCardDetailedJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full flex-col items-center justify-center gap-28 rounded-xl bg-wuiSlate-50 p-8 sm:p-16 [&>*]:w-80 [&>*]:max-w-full">
              <RatingAllCardDetailedPreview
                RatingCardDetailed={
                  activeTabs[6].active_tab === 1
                    ? RatingCardDetailedHTML.default
                    : RatingCardDetailedJsx.default
                }
                RatingCardDetailedEmphasized={
                  activeTabs[6].active_tab === 1
                    ? RatingCardDetailedEmphasizedHTML.default
                    : RatingCardDetailedEmphasizedJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Basic */}
          <h3 id="RatingAllCardBrand">Card Brand</h3>

          <PreviewBlock
            id="RatingAllCardBrand"
            HtmlComponent={RatingCardBrandHTML.default}
            JsxComponent={RatingCardBrandJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div className="flex w-full flex-col items-center justify-center gap-28 rounded-xl bg-wuiSlate-50 p-8 sm:p-16 [&>*]:w-80 [&>*]:max-w-full">
              <RatingAllCardBrandPreview
                RatingCardBrand={
                  activeTabs[7].active_tab === 1
                    ? RatingCardBrandHTML.default
                    : RatingCardBrandJsx.default
                }
                RatingCardBrandEmphasized={
                  activeTabs[7].active_tab === 1
                    ? RatingCardBrandEmphasizedHTML.default
                    : RatingCardBrandEmphasizedJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>Use the rating component to:</p>
          <ul>
            <li>
              To display a user's rating for a product, app, service etc, and
              thus notify other users about the product's quality.
            </li>
            <li>
              To provide details on how the product or service is rated (with
              the detailed variation) and also feedback on what's the total
              number of rates.
            </li>
          </ul>
        </Section>

        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>The rating component include the following elements:</p>
          <ul>
            <li>
              <strong>Star icon:</strong> 1.5rem (24px) in width and heigh (or
              1rem (16px) for the list variation)t. Star ratings have 5
              available stars. This shouldn’t be increased or decreased to fit
              various containers.
            </li>
            <li>
              <strong>Feedback:</strong> additional text that provides more
              information about the rating (exact score, number of rates etc).
              Text can be 0.875rem (14px) or 0.75rem (12px).
            </li>
            <li>
              <strong>Progress bars:</strong> see{" "}
              <Link to="/components/progress-bars">progress bars.</Link>
            </li>
          </ul>
        </Section>

        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <p>For more accessible ratings:</p>
          <ul>
            <li>
              Use <code>aria-label</code> on the wrapper{" "}
              <code>&lt;span&gt;</code> to notify screen readers on the exact
              rating.
            </li>
            <li>
              Use <code>aria-hidden=&rdquo;true&rdquo;</code> on the star svgs
              to indicate that the element is not exposed to the accessibility
              API.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}
